<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button"/>
    </div>

    <!-- Table section -->
    <div class="table-container-order-details">
        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Tipo de nota</th>
                    <th>Datos del vehículo</th>
                    <th>Referencias de origen</th>
                    <th>Referencias de destino</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="assignation">
                    <td>
                        {{ assignation.id }}
                    </td>
                    <td><b>Calle: </b>{{ assignation.assistance_note.source.street_name }}<br> <b>Número: </b>{{ assignation.assistance_note.source.number }}<br> <b>Entre calles: </b>{{ assignation.assistance_note.source.between_streets }}<br> <b>Colonia: </b>{{ assignation.assistance_note.source.neighborhood }}<br> <b>Ciudad: </b>{{ assignation.assistance_note.source.city }}<br> <b>Estado: </b>{{ assignation.assistance_note.source.state }}</td>
                    <td><b>Calle: </b>{{ assignation.assistance_note.destination.street_name }}<br> <b>Número: </b>{{ assignation.assistance_note.destination.number }}<br> <b>Entre calles: </b>{{ assignation.assistance_note.destination.between_streets }}<br> <b>Colonia: </b>{{ assignation.assistance_note.destination.neighborhood }}<br> <b>Ciudad: </b>{{ assignation.assistance_note.destination.city }}<br> <b>Estado: </b>{{ assignation.assistance_note.destination.state }}</td>
                    <td>{{ assignation.assistance_note_type }}</td>
                    <td><b>Marca: </b>{{ assignation.assistance_note_brand }}<br><b>Submarca: </b>{{ assignation.assistance_note_subbrand }}<br><b>Color: </b>{{ assignation.assistance_note.vehicle_color }}<br><b>Placas: </b>{{ assignation.assistance_note.vehicle_plates }}<br></td>
                    <td><b>Referencias: </b>{{ assignation.assistance_note.source_notes }}<br></td>
                    <td><b>Referencias: </b>{{ assignation.assistance_note.destination_notes }}<br></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="open-google-maps-button">
        <button class="open-google-button" @click="getUserLocationAndOpenGoogleMaps">Abrir en Google Maps</button>
    </div>
    <div class="map">
        <div id="map" style="width: 100%; height: 100%"></div>
    </div>
    <div class="text-register">
        <h1>Registrar</h1>
    </div>
    <button v-if="!assignation.start_time && OkButton" class="btn1" @click="showButtons">
        <h2>Aceptar</h2>
    </button>
    <div v-if="assignation.start_time || showButtonsforOperator">
        <div class="buttons-operator">
            <button class="btn1" :class="{'clicked': assignation.start_time}" @click="!assignation.start_time && confirmAndSendDateTime('inicio', 'start_time')">
                <h2>Inicio</h2>
            </button>
            <button class="btn2" :class="{'clicked': assignation.arrival_time}" @click="assignation.start_time && !assignation.arrival_time && confirmAndSendDateTime('arribo', 'arrival_time')">
                <h2>Arribo</h2>
            </button>
            <button class="btn3" :class="{'clicked': assignation.contact_time}" @click="assignation.arrival_time && !assignation.contact_time && confirmAndSendDateTime('contacto', 'contact_time')">
                <h2>Contacto</h2>
            </button>
            <button class="btn4" :class="{'clicked': assignation.end_time}" @click="assignation.contact_time && !assignation.end_time && confirmAndSendDateTime('termino', 'end_time')">
                <h2>Termino</h2>
            </button>
        </div>
    </div>
    <div class="text-km">
        <h1>Kilometraje</h1>
    </div>
    <div class="form-section">
        <div class="form-group-inventory">
            <label for="start_km">KM inicio</label>
            <input type="text" id="start_km" v-model="assignation.start_km" placeholder="Ingrese el km de inicio"/>
        </div>
    </div>
    <div class="form-section">
        <div class="form-group-inventory">
            <label for="end_km">KM fin</label>
            <input type="text" id="end_km" v-model="assignation.end_km" placeholder="Ingrese el km de fin"/>
        </div>
    </div>
    <div class="form-section">
    <button id="updateAssignationButton" style="background-color: green; color: white; padding: 10px 20px; border: none; border-radius: 5px; cursor: pointer;" @click="updateUnitTachometer">
        Actualizar
    </button>
</div>
    <div class="text-inventory">
        <h1>Inventario</h1>
    </div>

    <div class="checkbox-group-inventory">
      <input type="checkbox" id="extinguidor" v-model="assignation.assistance_note.vehicle_inventory.extinguidor">
      <label for="extinguidor">Extinguidor</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="cables_pasa_corriente" v-model="assignation.assistance_note.vehicle_inventory.cablesPasaCorriente">
      <label for="cables_pasa_corriente">Cables pasa corriente</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="llave_birlo_seguridad" v-model="assignation.assistance_note.vehicle_inventory.llaveBirloSeguridad">
      <label for="llave_birlo_seguridad">Llave de birlo de seguridad</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="llave_ruedas" v-model="assignation.assistance_note.vehicle_inventory.llaveRuedas">
      <label for="llave_ruedas">Llave de ruedas</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="senales_carretera" v-model="assignation.assistance_note.vehicle_inventory.senalesCarretera">
      <label for="senales_carretera">Señales de carretera</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="tarjeta_circulacion" v-model="assignation.assistance_note.vehicle_inventory.tarjetaCirculacion">
      <label for="tarjeta_circulacion">Tarjeta de circulación</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="permiso_importacion" v-model="assignation.assistance_note.vehicle_inventory.permisoImportacion">
      <label for="permiso_importacion">Permiso de importación</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="llaves" v-model="assignation.assistance_note.vehicle_inventory.llaves">
      <label for="llaves">Llaves</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="llavero" v-model="assignation.assistance_note.vehicle_inventory.llavero">
      <label for="llavero">Llavero</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="control" v-model="assignation.assistance_note.vehicle_inventory.control">
      <label for="control">Control</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="antena" v-model="assignation.assistance_note.vehicle_inventory.antena">
      <label for="antena">Antena</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="canastilla" v-model="assignation.assistance_note.vehicle_inventory.canastilla">
      <label for="canastilla">Canastilla</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="emblemas" v-model="assignation.assistance_note.vehicle_inventory.emblemas">
      <label for="emblemas">Emblemas</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="espoilers_delantero" v-model="assignation.assistance_note.vehicle_inventory.espoilersDelantero">
      <label for="espoilers_delantero">Espoilers delantero</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="espoilers_trasero" v-model="assignation.assistance_note.vehicle_inventory.espoilersTrasero">
      <label for="espoilers_trasero">Espoilers trasero</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="tapones_ruedas" v-model="assignation.assistance_note.vehicle_inventory.taponesRuedas">
      <label for="tapones_ruedas">Tapones ruedas</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="tapon_gasolina" v-model="assignation.assistance_note.vehicle_inventory.taponGasolina">
      <label for="tapon_gasolina">Tapón gasolina</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="tapa_armella_enganche" v-model="assignation.assistance_note.vehicle_inventory.tapaArmellaEnganche">
      <label for="tapa_armella_enganche">Tapa de armella de enganche</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="porta_placas" v-model="assignation.assistance_note.vehicle_inventory.portaPlacas">
      <label for="porta_placas">Porta placas</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="placas_delanteras" v-model="assignation.assistance_note.vehicle_inventory.placasDelanteras">
      <label for="placas_delanteras">Placas delanteras</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="placas_trasera" v-model="assignation.assistance_note.vehicle_inventory.placasTrasera">
      <label for="placas_trasera">Placas trasera</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="toldo" v-model="assignation.assistance_note.vehicle_inventory.toldo">
      <label for="toldo">Toldo</label>
    </div>
    <div class="checkbox-group-inventory">
      <input type="checkbox" id="quemacocos" v-model="assignation.assistance_note.vehicle_inventory.quemacocos">
      <label for="quemacocos">Quemacocos</label>
    </div>
    
    <div class="form-section">
      <div class="form-group-inventory">
        <label for="marca_bateria">Marca batería</label>
        <input type="text" id="marca_bateria" v-model="assignation.assistance_note.vehicle_inventory.marcaBateria" placeholder="Comentarios" />
      </div>
    </div>
    <div class="form-section">
      <div class="form-group-inventory">
        <label for="marca_radio">Marca radio estéreo</label>
        <input type="text" id="marca_radio" v-model="assignation.assistance_note.vehicle_inventory.marcaRadio" placeholder="Comentarios" />
      </div>
    </div>
    <div class="form-section">
      <div class="form-group-inventory">
        <label for="numero_bocinas">Número de bocinas</label>
        <input type="text" id="numero_bocinas" v-model="assignation.assistance_note.vehicle_inventory.numeroBocinas" placeholder="Comentarios" />
      </div>
    </div>
    <div class="form-section">
      <div class="form-group-inventory">
        <label for="numero_herramientas">Número de herramientas</label>
        <input type="text" id="numero_herramientas" v-model="assignation.assistance_note.vehicle_inventory.numeroHerramientas" placeholder="Comentarios" />
      </div>
    </div>
    <div class="form-section">
      <div class="form-group-inventory">
        <label for="tipo_gato">Tipo de gato</label>
        <input type="text" id="tipo_gato" v-model="assignation.assistance_note.vehicle_inventory.tipoGato" placeholder="Comentarios" />
      </div>
    </div>
    <div class="form-section">
      <div class="form-group-inventory">
        <label for="marca_llanta_refaccion">Marca llanta de refacción</label>
        <input type="text" id="marca_llanta_refaccion" v-model="assignation.assistance_note.vehicle_inventory.marcaLlantaRefaccion" placeholder="Comentarios" />
      </div>
    </div>
    <div class="form-section">
      <div class="form-group-inventory">
        <label for="rines">Rines</label>
        <input type="text" id="rines" v-model="assignation.assistance_note.vehicle_inventory.rines" placeholder="Escribir originales u Otra marca" />
      </div>
    </div>
    <div class="form-section">
      <div class="form-group-inventory">
        <label for="marca_llantas_vehiculo">Marca llantas del vehículo</label>
        <input type="text" id="marca_llantas_vehiculo" v-model="assignation.assistance_note.vehicle_inventory.marcaLlantasVehiculo" placeholder="Comentarios" />
      </div>
    </div>
    <div class="form-section">
      <div class="form-group-inventory">
        <label for="vida_llantas">Vida de las llantas</label>
        <input type="text" id="vida_llanta_del_izq" v-model="assignation.assistance_note.vehicle_inventory.vidaLlantasdelanteraIzq" placeholder="Delantera Izq" />
        <input type="text" id="vida_llanta_del_der" v-model="assignation.assistance_note.vehicle_inventory.vidaLlantasdelanteraDer" placeholder="Delantera Der" />
        <input type="text" id="vida_llanta_tras_izq" v-model="assignation.assistance_note.vehicle_inventory.vidaLlantastraseraIzq" placeholder="Trasera Izq" />
        <input type="text" id="vida_llanta_tras_der" v-model="assignation.assistance_note.vehicle_inventory.vidaLlantastraseraDer" placeholder="Trasera Der" />
      </div>
    </div>
        <div class="form-section">
        <button id="updateAssignationButton" style="background-color: green; color: white; padding: 10px 20px; border: none; border-radius: 5px; cursor: pointer;" @click="saveInventory">
        Actualizar inventario
    </button>
</div>
    
    <div class="text-images">
        <h1>Evidencia</h1>
    </div>

    <div>
        <div class="form-section-images-operator" v-for="section in imageSections" :key="section.loc">
    <div class="form-group-images-operator">
        <label>{{ section.label }}</label>
    </div>
    <div class="image-container-operator" v-if="section.images.default">
        <img :src="section.images.default">
    </div>
    <div class="image-container-upload-operator" v-for="(image, index) in section.images.uploaded" :key="index">
        <div class="image-wrapper">
            <img :src="image">
            <button class="delete-button" @click="deleteImage(section,index)">X</button>
        </div>
    </div>
    <button class="btn-submit-upload-images" @click="uploadFile(section)">Subir imágen</button>
</div>
    </div>

<!-- Signature Section -->
<div class="signature-container">
    <h3>Firma del Cliente:</h3>
    <div class="signature-box">
    <canvas ref="signatureCanvas" class="signature-canvas"></canvas>
</div>
    <div class="signature-actions">
        <button @click="clearSignature">Limpiar Firma</button>
        <button @click="saveSignature">Guardar Firma</button>
    </div>
</div>
</template>

<style>
.nav-bar-operations {
    position: absolute; /* Ensures the navigation bar stays fixed on the viewport */
    top: 0;
    left: 0;
    width: 100vw; /* Full width of the viewport */
    height: 10vh; /* Adjust height as needed */
    background-color: #FFC434;
    z-index: 1000; /* Ensure it's above other content */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute; /* Position the logo absolutely within the navigation bar */
    /* Make the logo cover the entire width of the navigation bar */
    height: 90%; /* Make the logo cover the entire height of the navigation bar */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.id-span {
    cursor: pointer;
    background-color: #FFC434;
    color: black;
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 3px;
}
.table-container-order-details {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 83px; /* Adjust as needed */
    padding: 10px;
}
.table {
    width: 100%;
    border-collapse: collapse;
}
.table th, .table td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
}
.table th, .table td {
    border-left: none; /* Remove left border */
    border-right: none; 
    padding: 8px;
    text-align: left;
    font-size: 0.9em;
}
.table tr {
    border-left: none; /* Removes the left border of the row */
    border-right: none; 
}
.table tbody tr:first-child td {
    border-top: none;
}
.table tbody tr:hover td{
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}
/* Style for the last row of the tbody */
.table tbody tr:last-child td {
    border-bottom: none;
}
.map {
    top: 10vh;
    width: 100%;
    height: 50vh;
}
.buttons-operator{
    display: flex;
    flex-wrap: wrap; /* Allows elements to wrap across multiple lines */
    align-items: center;
    justify-content: space-around; /* Distributes space evenly */
    margin: 0 auto; /* Center the button container */
    padding: 10px; /* Space around buttons */
}
.btn1,
.btn2,
.btn3,
.btn4 {
    flex: 1 0 calc(50% - 20px); /* Each button will take 50% of the container's width minus 20px */
    margin: 5px; /* Space between buttons */
    background-color: rgb(255, 0, 0) !important; 
    color: white !important; 
    padding: 2px 5px; 
    border-radius: 10px;
    text-align: center; /* Ensures that the text is centered */
}
.clicked {
    background-color: green !important; /* Use !important to override other styles if necessary */
    pointer-events: none; /* Disable click events */
}
.text-register{
    background-color: #FFC434; 
    color: rgb(0, 0, 0); 
    padding: 2px 5px; 
    border-radius: 3px;
}
.text-images{
    background-color: #0044ff; 
    color: rgb(0, 0, 0); 
    padding: 2px 5px; 
    border-radius: 3px;
}
.text-km{
    background-color: #09ff00; 
    color: rgb(255, 255, 255); 
    padding: 2px 5px; 
    border-radius: 3px;
    margin-bottom: 2vh;
}
.text-inventory{
    background-color: #ff0000; 
    color: rgb(255, 255, 255); 
    padding: 2px 5px; 
    border-radius: 3px;
    margin-bottom: 2vh;
}
.text-register h1{
    font-size: 3rem;
}
.text-images h1{
    font-size: 3rem;
}
.text-km h1{
    font-size: 3rem;
}
.text-inventory h1{
    font-size: 3rem;
}
.checkbox-group-inventory {
    display: flex;
    justify-content: flex-start; 
    gap: 5px; 
    font-weight: bold;
    margin-left: 5vw;
}
.checkbox-group-inventory input{
    margin-bottom: 1vh;
}
.form-group-inventory label {
    text-align: left; /* Alinea el texto de la etiqueta a la izquierda */
    font-weight: bold; /* Hace que el texto de la etiqueta sea negrita */
    display: inline-block; /* Hace que la etiqueta sea en bloque pero alinee su contenido */
    width: 400%; /* Asegura que la etiqueta ocupe todo el ancho disponible */
}
.form-group-inventory input{
    width: 100%;
}
.open-google-button {
    text-align: center; 
    margin: 20px 0; 
    background-color: rgb(255, 0, 0); 
    color: white ; 
    padding: 10px 5px; 
    border-radius: 10px;
    text-align: center;
}
.btn-submit-upload-images{
    background-color: #b1d0ff; 
    color: #000000; 
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}
.form-section-images-operator{
    flex-basis: calc(33% - 10px); /* Ajuste para margen entre secciones */
    margin-right: 10px; /* Margen entre secciones */
    background: #fff; /* Fondo para cada sección del formulario */
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1); /* Sombra suave para resaltar secciones */
}
.form-section-images-operator img{
    width: 100px;
    height: 100px;
}
.image-container-operator {
    margin-top: 5vh;
    width: 100%;
    height: 200px;
}
.image-container-upload-operator {
    margin-top: 2vh;
    margin-bottom: 3vh;
    width: 100%;
    height: 200px;
}
.image-container-upload-operator img{
    width: 200px !important;
    height: 200px !important;
}
@media (max-width: 600px) {
  .buttons-operator {
      justify-content: space-around; /* Uniform spacing around buttons */
  }
  .btn1, .btn2, .btn3, .btn4 {
      margin-bottom: 5px; /* Ensure space between buttons on separate rows */
      width: calc(50% - 10px); /* Calculates the width for two buttons per row with space in between */
  }
}

.signature-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signature-pad {
    border: 1px solid black; /* Add a visible black border */
    box-sizing: border-box; /* Ensure padding and border are included in width/height */
    width: 100%; /* Canvas scales to fit container */
    height: 200px; /* Set a fixed height, adjust as needed */
    max-width: 500px; /* Optional: limit max width */
    touch-action: none; /* Prevent touch gestures from interfering */
    background-color: white; /* Optional: white background for clarity */
}

.signature-actions button {
    margin: 10px;
    padding: 8px 15px;
    background-color: #FFC434;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.signature-actions button:hover {
    background-color: #E3AB00;
}

</style>

<script>
/* global google */

import axios from 'axios';
import Swal from 'sweetalert2';
import Compressor from 'compressorjs';
import SignaturePad from 'signature_pad';


export default {

    props : ['assignations'],
    data() {
        return {
            signatureOptions: {
            penColor: "black",
            backgroundColor: "rgba(255,255,255,1)",
            throttle: 16, // Smooth out strokes
        },
        assignation: {
        assistance_note: {
          vehicle_inventory: {
            extinguidor: null,
            cablesPasaCorriente: null,
            llaveBirloSeguridad: null,
            llaveRuedas: null,
            senalesCarretera: null,
            tarjetaCirculacion: null,
            permisoImportacion: null,
            llaves: null,
            llavero: null,
            control: null,
            antena: null,
            canastilla: null,
            emblemas: null,
            espoilersDelantero: null,
            espoilersTrasero: null,
            taponesRuedas: null,
            taponGasolina: null,
            tapaArmellaEnganche: null,
            portaPlacas: null,
            placasDelanteras: null,
            placasTrasera: null,
            toldo: null,
            quemacocos: null,
            marcaBateria: "",
            marcaRadio: "",
            numeroBocinas: "",
            numeroHerramientas: "",
            tipoGato: "",
            marcaLlantaRefaccion: "",
            rines: "",
            marcaLlantasVehiculo: "",
            vidaLlantasdelanteraIzq: "",
            vidaLlantasdelanteraDer: "",
            vidaLlantastraseraIzq: "",
            vidaLlantastraseraDer: "",

          },
          source: {
            lat: null,
            lon: null
          },
          destination: {
            lat: null,
            lon: null
          }
        },
        client_signature: null
      },
            clickedButtons: {
                start: false,
                arrival: false,
                contact: false,
                end: false
            },
            OkButton: true,
            showButtonsforOperator: false,
            mapCenter: { lat: 40.7128, lng: -74.006 }, // Define the center of the map
            origin: {lat: 40.7128, lng: -74.006},
            destination: {lat: 40.7128, lng: -74.006},
            imageSections: [ // Hold the image data in a modular way
                { loc: 'front', label: 'Fotografías de la parte del frente del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'engine', label: 'Fotografías del motor del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'left', label: 'Fotografías del lado izquierdo del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'right', label: 'Fotografías del lado derecho del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'back', label: 'Fotografías de la parte trasera del vehículo', images: { default: '', uploaded: [], blob_ids: [] } },
                { loc: 'complementary', label: 'Fotografías complementarias del vehículo (Llantas, Cajuela, interior del vehículo, etc.)', images: { default: '', uploaded: [], blob_ids: [] } },
            ],
            signaturePad: null,
        };
    },
    mounted() {
        const canvas = this.$refs.signatureCanvas;
        this.signaturePad = new SignaturePad(canvas);
    window.addEventListener('resize', this.resizeCanvas);
    },
    beforeUnmount() {
    window.removeEventListener('resize', this.resizeCanvas);
},
    methods: {
        loadSignature(signatureDataUrl) {
        const image = new Image();
        image.src = signatureDataUrl;

        image.onload = () => {
            const canvas = this.$refs.signatureCanvas;
            const context = canvas.getContext('2d');

            // Clear the canvas and draw the saved signature
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
    },
        resizeCanvas() {
        const canvas = this.$refs.signaturePad.$el.querySelector('canvas');
        const ratio = Math.max(window.devicePixelRatio || 1, 1);

        // Set the canvas size to its rendered dimensions
        const width = canvas.offsetWidth;
        const height = canvas.offsetHeight;

        // Scale the canvas for high-DPI screens
        canvas.width = width * ratio;
        canvas.height = height * ratio;
        canvas.getContext('2d').scale(ratio, ratio);

        // Clear and reset the signature pad
        this.$refs.signaturePad.clear();
    },
        clearSignature() {
            this.signaturePad.clear();
        },
        saveSignature() {
    // Get the signature in Base64 format
    const signatureDataUrl = this.signaturePad.toDataURL('image/png'); // You can choose different image formats (e.g., PNG, JPEG)

    // Check if the signature pad has been drawn on
    if (this.signaturePad.isEmpty()) {
        Swal.fire({
            title: '¡Error!',
            text: 'La firma está vacía. Por favor, firme antes de guardar.',
            icon: 'error',
            confirmButtonText: 'Ok'
        });
        return;
    }

    // Prepare the payload for sending to the server
    const payload = {
        client_signature: signatureDataUrl, // Base64 signature image
        assignation_id: this.assignation.id // Include the assignation ID
    };

    // Send the signature to your server
    const apiDirectory = process.env.API_DIRECTORY;
    axios.post(`${apiDirectory}/save-signature/`, payload)
        .then(() => {
            // Handle the response (e.g., show a success message)
            Swal.fire({
                title: '¡Completado!',
                text: 'La firma se guardó correctamente.',
                icon: 'success',
                confirmButtonText: 'Ok'
            });
        })
        .catch((error) => {
            // Handle any errors that occurred during the request
            console.error('Error saving signature:', error);
            Swal.fire({
                title: '¡Error!',
                text: 'Ocurrió un error al guardar la firma. Intente nuevamente.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        });
},
        confirmAndSendDateTime(button, timeKey) {
            Swal.fire({
                title: '¿Estás seguro?',
                text: `¿Quieres marcar el ${button}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, hacerlo!',
                cancelButtonText: 'No, cancelar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.sendDateTime(button, timeKey);
                }
            });
        },
        async sendDateTime(button, timeKey) {
            const dateTime = new Date().toISOString();
            this.assignation[timeKey] = dateTime;

            // Check if the note is in status 3 before updating start_time
            if (timeKey === 'start_time' && this.assignation.assistance_note.status === 3) {
                this.assignation.assistance_note.status = 1; // Set to active status
            }

            await this.updateAssignationStatus(timeKey, dateTime);

            if (button == 'termino') {
                try {
                    const payload = [{"unit": this.assignation.unit.unit_short_id}];
                    const apiDirectory = process.env.API_DIRECTORY;
                    const response = await axios.post(`${apiDirectory}/auto-update-unit-status`, payload);
                    console.log(response);
                } catch (error) {
                    console.error('Error updating unit status:', error);
                }
            }
        },
        async updateAssignationStatus(timeKey, dateTime) {
            const payload = {};
            payload[timeKey] = dateTime;
            const assignation_id = this.assignation.id;

            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.patch(`${apiDirectory}/assignation-detail/${assignation_id}/`, payload);
                console.log(response.data);
            } catch (error) {
                console.error('Error updating assignation status:', error);
            }
        },
        async saveInventory(){
           const noteID = this.assignation.assistance_note.note_ident
           const apiDirectory = process.env.API_DIRECTORY;
           await axios.put(`${apiDirectory}/notes/${noteID}/`, this.assignation.assistance_note);
           Swal.fire(
                    '¡Actualizado!',
                    'Los datos del formulario se han actualizado con éxito.',
                    'success'
                ).then((result) => {
                if (result.isConfirmed) window.location.reload();
                });
        },
        async updateUnitTachometer() {
            // const payload = {source_km: 23};
            const assignation_id = this.assignation.id;
            const assignationData = { ...this.assignation };
            assignationData["unit"] = this.assignation.unit.id;
            assignationData["assistance_note"] = this.assignation.assistance_note.id;
            assignationData["assigned_by"] = this.assignation.assigned_by.id;
            assignationData["operator"] = this.assignation.operator.id;
            // assignation["assistance_note"] = this.assignation.assistance_note.id;
            console.log(assignationData);
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.patch(`${apiDirectory}/assignation-detail/${assignation_id}/`, assignationData);
                console.log(response.data);
                Swal.fire({
                    title: '¡Completado!',
                    text: 'El kilometraje se actualizo con exito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        location.reload(); // Reload the page after 'Ok' is clicked
                    }
                }); // Reload the page after 'Ok' is clicked);
            } catch (error) {
                console.error('Error updating assignation data:', error);
                Swal.fire(
                        '¡Error!',
                        'Verifique que los kilometrajes introducidos son válidos',
                        'error'
                    );
            }
        },
        async fetchAssignations() {
            const assignation_id = this.$route.params.assignation_id;
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/assignation-detail/${assignation_id}/` );
                this.assignation = response.data;
                if (this.assignation.client_signature) {
        this.loadSignature(this.assignation.client_signature);
    }
                
                this.origin = {
                    lat: parseFloat(this.assignation.assistance_note.source.lat),
                    lng: parseFloat(this.assignation.assistance_note.source.lon)
                };
                this.destination = {
                    lat: parseFloat(this.assignation.assistance_note.destination.lat),
                    lng: parseFloat(this.assignation.assistance_note.destination.lon)
                };
                this.initializeMap();
                console.log(this.origin);
                
            } catch (error) {
                console.error('Failed to fetch assignations:', error);
            }
        },
        loadGoogleMapsAPI() {
            // Load Google Maps API dynamically
            const script = document.createElement('script');
            const apiKey = process.env.GOOGLE_API_KEY;
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                this.initializeMap();
            };
            document.head.appendChild(script);
        },
        initializeMap() {
            const directionsService = new google.maps.DirectionsService();
            const directionsRenderer = new google.maps.DirectionsRenderer({
                polylineOptions: {
                    strokeColor: 'violet'
                },
                map: new google.maps.Map(document.getElementById('map'), {
                    zoom: 10,
                    center: this.mapCenter
                })
            });

            const request = {
                origin: this.origin,
                destination: this.destination,
                travelMode: 'DRIVING'
            };

            directionsService.route(request, (result, status) => {
                if (status === 'OK') {
                    directionsRenderer.setDirections(result);
                } else {
                    console.error('Error fetching route:', status);
                }
            });
        },
        getUserLocationAndOpenGoogleMaps() {
            if (!navigator.geolocation) {
                Swal.fire(
                    'Error',
                    'La geolocalización no está soportada por este navegador.',
                    'error'
                );
            return;
            }

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLocation = `${position.coords.latitude},${position.coords.longitude}`;
                    const origin = `${this.assignation.assistance_note.source.lat},${this.assignation.assistance_note.source.lon}`;
                    const destination = `${this.assignation.assistance_note.destination.lat},${this.assignation.assistance_note.destination.lon}`;

                    const googleMapsURL = `https://www.google.com/maps/dir/?api=1&waypoints=${origin}&origin=${userLocation}&destination=${destination}&travelmode=driving`;
                    window.open(googleMapsURL, '_blank');
                },
                () => {
                    Swal.fire(
                        'Error',
                        'No se pudo obtener la ubicación actual.',
                        'error'
                    );
                }
            );
        },
        showButtons() {
            this.OkButton = false;
            this.showButtonsforOperator = true;
        },
        async fetchImages() {
            //Retrieves all the images linked to the current note 
            try {
                const apiDirectory = process.env.API_DIRECTORY;
                const response = await axios.get(`${apiDirectory}/get-files/`, {
                    params: {
                        object_id: this.assignation.assistance_note.note_ident,
                        object_type: 'note',
                    },
                });
                const files = response.data;
                this.clearImages(); // Clear existing images before populating
                this.populateImages(files);
                this.buttonClicked.images_tab = true;
                //console.log('Populated image blobs are ',this.imageSections)
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        },
        populateImages(files) {
            files.forEach(file => {
                const section = this.imageSections.find(section => section.loc === file.metadata.loc);
                if (section) {
                    section.images.uploaded.push(`data:image/jpeg;base64,${file.content}`);
                    section.images.blob_ids.push(file.file_id);
                }
            });
        },
        clearImages() {
            this.imageSections.forEach(section => {
                section.images.uploaded = [];
                section.images.blob_ids = [];
            });
        },
        getProportionalCompressionQuality(size) {
            // Define the max size and min/max quality
            const maxSize = 10000000; // 10MB as the maximum file size for the highest quality
            const minQuality = 0.4; // Minimum quality
            const maxQuality = 0.9; // Maximum quality

            // Calculate the proportional quality
            const quality = Math.max(minQuality, Math.min(maxQuality, maxQuality - (size / maxSize) * (maxQuality - minQuality)));

            return quality;
        },
        // Function to compress and convert to JPEG
        async compressAndConvertToJPG(file) {
            return new Promise((resolve, reject) => {
                // Determine the compression quality based on the file size
                const initialSize = file.size;
                const quality = this.getProportionalCompressionQuality(initialSize);

                new Compressor(file, {
                    quality: quality,
                    convertSize: 5000000, // Convert if size is larger than 5MB
                    success(result) {
                        const reader = new FileReader();
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = () => reject(new Error('Error reading the file.'));
                        reader.readAsDataURL(result);
                    },
                    error(err) {
                        reject(err);
                    },
                });
            });
        },
        selectFile() {
            // Used to handle the image uploads
            return new Promise((resolve) => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.onchange = (event) => {
                    resolve(event.target.files[0]);
                };
                input.click();
            });
        },
        async uploadFile(section) {
            // Handles the upload of images to the upload-file endpoint
            const file = await this.selectFile();
            if (!file) {
                Swal.fire({
                    title: '¡Error!',
                    text: 'Ocurrio un error al subir el archivo. Intente de nuevo.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // return from func
                        return;
                    }
                });
                return;
            }

            try {
                const compressedFile = await this.compressAndConvertToJPG(file);

                // Create a new blob from the base64 data
                const response = await fetch(compressedFile);
                const blob = await response.blob();
                const jpgFile = new File([blob], 'image.jpg', { type: 'image/jpeg' });

                const formData = new FormData();
                formData.append('file', jpgFile);
                formData.append('object_type', 'note');
                formData.append('object_id', this.assignation.assistance_note.note_ident);
                formData.append('object_metadata', JSON.stringify({ 'loc': section.loc }));

                const apiDirectory = process.env.API_DIRECTORY;
                await axios.post(`${apiDirectory}/upload-file`, formData);
                this.fetchImages();
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },
        async deleteImage(section, index) {

            // Find the corresponding blob ID
            const blobId = section.images.blob_ids[index];

            if (!blobId) {
                Swal.fire({
                    title: '¡Error!',
                    text: 'Ocurrio un error al eliminar el archivo. Intente de nuevo. Si el problema persiste, contacte a su administrador.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        return
                    }
                });
            }

            try {
                const apiDirectory = process.env.API_DIRECTORY;
                await axios.post(`${apiDirectory}/delete-file/`, { blob_id: blobId });

                // Remove the image from the section and the blob id from the dict 
                section.images.uploaded.splice(index, 1);
                section.images.blob_ids.splice(index, 1);


                Swal.fire({
                    title: '¡Completado!',
                    text: 'La imagen se eliminó con éxito',
                    icon: 'success',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok'
                })
            } catch (error) {
                console.error('Error deleting image:', error);
                Swal.fire({
                    title: '¡Error!',
                    text: 'Ocurrio un error al procesar la solicitud para eliminar la imagen. Por favor intente de nuevo. Si el problema periste, contacte a su administraodr.',
                    icon: 'error',
                    showCancelButton: false, // Hide cancel button
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // return from func
                        return
                    }
                });
            }
        },
    },
    async created(){
        await this.fetchAssignations();
        this.loadGoogleMapsAPI();
        this.fetchImages();
    },
};
</script>